import { useQuery } from '@tanstack/react-query';
import { getWithAuth, postWithAuth } from '../../../api';

export const queryCacheKeys = (projectId, isTemplate, id) => {
  const path = isTemplate ? 'access_points/template' : 'access_point';
  return {
    key: [projectId, path, id],
    choicesKey: [projectId, path, id, 'choices'],
  };
};

export default function useNDData(projectId, isTemplate, id) {
  let url = `project/${projectId}/access_point/${id}`;
  if (isTemplate) {
    url = `project/${projectId}/access_points/template/${id}`;
  }
  const { key, choicesKey } = queryCacheKeys(projectId, isTemplate, id);
  const { data: apOrTemplate } = useQuery(key, async () => {
    const apOrTemplate = await getWithAuth(url);
    if (!isTemplate) {
      apOrTemplate.radios.forEach((radio) => {
        radio.antennas.forEach((antenna) => {
          antenna.latitude = antenna.latitude ?? apOrTemplate.site.latitude;
          antenna.longitude = antenna.longitude ?? apOrTemplate.site.longitude;
        });
      });
    }
    return apOrTemplate;
  });

  const { data: equipmentChoices } = useQuery(
    choicesKey,
    async () => {
      const choicesPayload = {
        project_id: projectId,
        ap: apOrTemplate,
      };
      const choices = await postWithAuth(
        'equipment/access_point/choices',
        choicesPayload
      );
      const equipmentChoiceMapOrTemplate = {};
      choices['equipment'].forEach((attr) => {
        equipmentChoiceMapOrTemplate[attr.attr_name] = attr;
      });
      choices['equipment'] = equipmentChoiceMapOrTemplate;
      return choices;
    },
    { enabled: apOrTemplate != null }
  );

  return { apOrTemplate, equipmentChoices };
}
