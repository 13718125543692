import React from 'react';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import { Controller, useFormContext } from 'react-hook-form';
import { useLinkKind } from './hooks';
import {
  Header,
  Segment,
  Table,
  Label,
  Grid,
  Button,
  Modal,
  Popup,
  Icon,
  Tab,
  Menu,
} from 'semantic-ui-react';
import additionalMessages from 'src/messages';
import { RECALC_COLOR } from 'src/app.constants';
import { withLineBreaks } from 'src/utils/useful_functions';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import StoredAccordion from 'src/components/StoredAccordion';
import { scaleAndFix } from 'src/components/controls/rhf/ScaledField';
import { GenericScaledField } from 'src/components/controls/rhf/GenericScaledField';
import { PTPChoices, PTPFormField, PTPFormPanel } from './utils';
import { getUnits } from 'src/model/ThroughputUnits';
import type { PTPPath } from './ptp-link-type';
import { syncPtpTabs } from './ptp.reducer';
import './PerformanceSummary.css';

/*
 * Create the popup report for the field if the field requires it
 */
const createPopup = (path, popupPath, kind) => {
  const [open, setOpen] = React.useState(false);

  if (!popupPath) {
    return null;
  }

  const reportData = get(path, popupPath);
  if (!reportData) {
    return null;
  }

  let titleName: string;
  if (kind === 'mesh') {
    titleName = `${path.local.name} to ${path.remote.name}`;
  } else {
    titleName = path.identifier;
  }

  const report = (
    <Table compact singleLine striped celled>
      {reportData.map((row, rowIdx) => (
        <Table.Row>
          {row.map((c, colIdx) => {
            if (rowIdx === 0) {
              return (
                <Table.HeaderCell textAlign="center">
                  {withLineBreaks(c)}
                </Table.HeaderCell>
              );
            } else {
              if (colIdx === 0) {
                return (
                  <Table.Cell>
                    <b>{withLineBreaks(c)}</b>
                  </Table.Cell>
                );
              } else {
                // if the last value is blank then
                // span the first value across both value columns
                const colSpan = row[2] === '' ? 2 : 1;
                if (colSpan === 2 && colIdx === 2) {
                  return null;
                }
                return (
                  <Table.Cell colSpan={colSpan} textAlign="center">
                    {withLineBreaks(c)}
                  </Table.Cell>
                );
              }
            }
          })}
        </Table.Row>
      ))}
    </Table>
  );

  return (
    <Modal
      trigger={
        <Button
          style={{ position: 'absolute', right: '-30px', bottom: '-1px' }}
          icon="info"
          basic
          type="button"
          size="mini"
        />
      }
      on="click"
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    >
      <Modal.Header>
        <FormattedMessage
          id="popup.availabilityTitle"
          defaultMessage="Lowest Mode Availability for"
        ></FormattedMessage>{' '}
        {titleName}
        <Button
          circular
          icon="close"
          title="Close"
          onClick={(e) => setOpen(false)}
          floated="right"
        />
      </Modal.Header>
      <Modal.Content>{report}</Modal.Content>
    </Modal>
  );
};

function PerformanceField(props: any) {
  const { id, field, path, setModified, possibleRecalc, pathIndex } = props;
  const { control, getValues } = useFormContext();
  const { permissionWrite } = useSelector((state: any) => state.mainFrame);
  const { isComplex, kind: lkKind, summaryLabels } = useLinkKind();

  let units, precision;
  if (field.calculateUnits) {
    [units, precision] = getUnits(getValues, field.precision);
  } else {
    units = field.units;
    precision = field.precision;
  }

  let getter;
  if (
    pathIndex != null &&
    isComplex &&
    field.lk != null &&
    field.lk.hasOwnProperty(lkKind)
  ) {
    getter = field.lk[lkKind].getter[pathIndex];
  } else {
    getter = field.getter;
  }

  const defaultValue = get(path, getter);
  const displayValue =
    precision != null ? Number(defaultValue).toFixed(precision) : defaultValue;

  const tooltip =
    field.tooltip != null
      ? field.tooltip({ path, pathIndex: pathIndex ?? 0 })
      : null;

  let style: any = {};
  const warning =
    field.warning != null &&
    field.warning({
      path,
      pathIndex: pathIndex ?? 0,
    });

  if (warning) {
    style.color = '#cc0000';
  }

  if (possibleRecalc && field.recalc) {
    style.color = RECALC_COLOR;
  }

  let warningPopup = null;
  let warningPopupProps = {};
  if (warning && field.warningPopup != null) {
    const warningPopupData = field.warningPopup({
      path,
      pathIndex: pathIndex ?? 0,
    });
    warningPopup = warningPopupData.message;
    warningPopupProps = {
      style: { color: warningPopupData.color },
      name: warningPopupData.isError ? 'warning sign' : 'warning circle',
    };
    style.color = warningPopupData.color;
  }

  let editable;
  if (isFunction(field.editable)) {
    editable = field.editable({ pathIndex, summaryLabels });
  } else {
    editable = field.editable;
  }

  if (editable) {
    return (
      <Table.Cell className="performance-summary-ctrl-container">
        <GenericScaledField
          key={getter}
          getter={getter}
          defaultValue={displayValue}
          min={field.min}
          max={field.max}
          units={units}
          precision={precision}
          dsiabled={!permissionWrite}
          setModified={setModified}
          watch={id}
          className="performance-summary-ctrl"
          hoverMessage={tooltip}
          style={{ width: '8rem' }}
        />
      </Table.Cell>
    );
  } else {
    return (
      <Table.Cell width={5} style={style} textAlign="right">
        <abbr title={tooltip}>
          {field.isDisplay
            ? displayValue
            : scaleAndFix(displayValue, units, precision, 'show')}{' '}
          {units}
        </abbr>
        {warning && warningPopup != null ? (
          <Popup
            style={style}
            content={warningPopup}
            trigger={
              <span
                style={{ marginLeft: '2px', color: warningPopupProps.color }}
              >
                <Icon size="large" {...warningPopupProps} />
              </span>
            }
          />
        ) : null}
      </Table.Cell>
    );
  }
}

function LinkPerformanceField(props: any) {
  const { id, field, path, choices, kind, setModified, bold, pathIndex } =
    props;
  const { isComplex, kind: lkKind } = useLinkKind();

  const { getValues } = useFormContext();
  const permissionWrite = useSelector(
    (state: any) => state.mainFrame.permissionWrite
  );

  let units, precision;
  if (field.calculateUnits) {
    [units, precision] = getUnits(getValues, field.precision);
  } else {
    units = field.units;
    precision = field.precision;
  }

  let getter;
  if (
    pathIndex != null &&
    isComplex &&
    field.lk != null &&
    field.lk.hasOwnProperty(lkKind)
  ) {
    getter = field.lk[lkKind].getter[pathIndex];
  } else {
    getter = field.getter;
  }

  const defaultValue = get(path, getter);
  const displayValue =
    precision != null ? defaultValue.toFixed(precision) : defaultValue;

  let label: string;
  if (typeof field.label === 'function') {
    label = field.label({ path, choices });
  } else {
    label = field.label;
  }

  let popupAttr;
  if (
    pathIndex != null &&
    isComplex &&
    field.lk != null &&
    field.lk.hasOwnProperty(lkKind) &&
    field.lk[lkKind].hasOwnProperty('popup')
  ) {
    popupAttr = field.lk[lkKind].popup[pathIndex];
  } else {
    popupAttr = field.popup;
  }
  const popupReport = createPopup(path, popupAttr, kind);

  const tooltip =
    field.tooltip != null
      ? field.tooltip({
          path,
          formGetter: getValues,
          pathIndex: pathIndex ?? 0,
        })
      : null;

  let control;
  let labelStyle: any = {};
  let valueStyle: any = {};

  const warning =
    field.warning != null &&
    field.warning({
      path,
      pathIndex: pathIndex ?? 0,
    });
  if (warning) {
    valueStyle.color = '#cc0000';
    labelStyle.color = '#cc0000';
  }

  if (!bold) {
    // labels default to bold
    labelStyle.fontWeight = 'normal';
  } else if (!field.editable) {
    // values default to normal
    valueStyle.fontWeight = 'bold';
  }

  if (field.editable) {
    control = (
      <div className="link-field-ctrl">
        <GenericScaledField
          getter={getter}
          defaultValue={defaultValue}
          min={field.min}
          max={field.max}
          units={units}
          precision={precision}
          disabled={!permissionWrite}
          setModified={setModified}
          watch={id}
          style={{ width: '10rem' }}
          hoverMessage={tooltip}
        />
      </div>
    );
  } else {
    control = (
      <div className={popupReport != null ? 'link-field-with-popup' : null}>
        <abbr title={tooltip} style={valueStyle}>
          {field.calculateUnits
            ? scaleAndFix(displayValue, units, precision, 'show')
            : displayValue}{' '}
          {units ? units : null}
        </abbr>
        {popupReport}
      </div>
    );
  }

  return (
    <>
      <Label basic className="no-border" style={labelStyle}>
        {label}
      </Label>
      {control}
    </>
  );
}

type SummaryProps = {
  id: string;
  path: any;
  config: any;
  choices?: PTPChoices;
  disabled: boolean;
  setModified: (arg: boolean) => void;
  possibleRecalc?: boolean;
  LinkComponent: any;
  panelKind: 'ptp' | 'mesh';
  intl: any;
};

function getValueByType(field, path, choices) {
  switch (typeof field[path]) {
    case 'function':
      return field[path]({ choices });
    default:
      return field[path];
  }
}

function BasePTPPerformanceSummary(props: SummaryProps) {
  const {
    id,
    path,
    config,
    choices,
    disabled,
    setModified,
    LinkComponent,
    panelKind,
    intl,
    possibleRecalc,
    pathIndex,
  } = props;
  const { formatMessage } = intl;
  const { summaryLabels } = useLinkKind();

  return (
    <>
      {disabled ? (
        <Segment inverted className="update-required">
          Apply your changes to update the performance summary
        </Segment>
      ) : null}
      <Segment basic style={{ container: 'ptp-link-summary / inline-size' }}>
        <div className="ptp-link-summary-grid">
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Header as="h5" textAlign="center">
              {formatMessage(additionalMessages.performanceToDevices)}
            </Header>
            <Table
              definition
              compact
              verticalAlign="middle"
              style={{ marginTop: '0' }}
            >
              <Table.Header style={{ textAlign: 'center' }}>
                <Table.Row>
                  <Table.HeaderCell />
                  <Table.HeaderCell>
                    Performance to
                    <br />
                    {panelKind === 'mesh'
                      ? path.local.name
                      : path.local.site.name}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    Performance to
                    <br />
                    {panelKind === 'mesh'
                      ? path.remote.name
                      : path.remote.site.name}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {[...Array(config.local.fields.length).keys()].map((i) => {
                  const fieldL = config.local.fields[i];
                  const fieldR = config.remote.fields[i];
                  if (
                    fieldL.show == null ||
                    fieldL.show({ path, choices, pathIndex, summaryLabels }) ||
                    fieldR.show({ path, choices, pathIndex, summaryLabels })
                  ) {
                    return (
                      <Table.Row key={`summary-${i}`}>
                        <Table.Cell width={6} textAlign="right">
                          {getValueByType(
                            config.local.fields[i],
                            'helpUrl',
                            choices
                          ) != null && (
                            <Button
                              icon="info"
                              basic
                              as="a"
                              href={getValueByType(
                                config.local.fields[i],
                                'helpUrl',
                                choices
                              )}
                              target="_blank"
                            />
                          )}

                          {getValueByType(
                            config.local.fields[i],
                            'label',
                            choices
                          )}
                        </Table.Cell>
                        <PerformanceField
                          id={id}
                          field={fieldL}
                          path={path}
                          setModified={setModified}
                          possibleRecalc={possibleRecalc}
                          pathIndex={pathIndex}
                        />
                        <PerformanceField
                          id={id}
                          field={fieldR}
                          path={path}
                          setModified={setModified}
                          possibleRecalc={possibleRecalc}
                          pathIndex={pathIndex}
                        />
                      </Table.Row>
                    );
                  }
                  return null;
                })}
              </Table.Body>
            </Table>
          </div>
          <div>
            <LinkComponent
              id={id}
              config={config}
              path={path}
              choices={choices}
              formatMessage={formatMessage}
              setModified={setModified}
              pathIndex={pathIndex}
            />
          </div>
        </div>
      </Segment>
    </>
  );
}

function BaseLinkSummary(props: any) {
  const { id, fields, path, kind, choices, setModified, pathIndex } = props;
  return (
    <div className="ptp-link-summary">
      <div className="link-summary">
        {fields.map((field: PTPFormField<any>) => {
          if (field.show != null && !field.show({ path, choices, pathIndex })) {
            return null;
          }

          return (
            <LinkPerformanceField
              key={field.getter}
              field={field}
              bold={field.bold}
              path={path}
              choices={choices}
              kind={kind}
              id={id}
              setModified={setModified}
              pathIndex={pathIndex}
            />
          );
        })}
      </div>
    </div>
  );
}

function MeshLinkSummary(props: any) {
  const { id, config, path, formatMessage, setModified } = props;
  return (
    <>
      <Header as="h5" textAlign="center">
        {formatMessage(additionalMessages.linkSummary)}
      </Header>
      <BaseLinkSummary
        id={id}
        fields={config.link.fields}
        path={path}
        kind="mesh"
        setModified={setModified}
      />
    </>
  );
}

export function MeshPerformanceSummary(props: any) {
  const { meshId } = useParams();
  const { path } = props;
  let worstEarthWarning: string = null;
  if (path.warnings != null && path.warnings.worst_earth?.length > 0) {
    worstEarthWarning = path.warnings.worst_earth;
  }

  return (
    <>
      <BasePTPPerformanceSummary
        id={meshId}
        LinkComponent={MeshLinkSummary}
        panelKind="mesh"
        {...props}
      />
      {worstEarthWarning != null ? (
        <Segment inverted color="red" tertiary>
          {worstEarthWarning}
        </Segment>
      ) : null}
    </>
  );
}

function PTPLinkSummary(props: any) {
  const { id, config, path, choices, formatMessage, setModified, pathIndex } =
    props;
  return (
    <>
      {config.link.map((panel: PTPFormPanel<any>) => {
        if (panel.show != null && !panel.show({ choices })) {
          return null;
        }

        let Component: any;
        if (panel.component != null) {
          Component = panel.component;
        } else {
          Component = BaseLinkSummary;
        }

        return (
          <React.Fragment key={panel.name}>
            <Header as="h5" textAlign="center">
              {formatMessage(panel.title ?? additionalMessages.linkSummary)}
            </Header>
            <Component
              id={id}
              fields={panel.fields}
              path={path}
              choices={choices}
              setModified={setModified}
              formatMessage={formatMessage}
              pathIndex={pathIndex}
            />
          </React.Fragment>
        );
      })}
    </>
  );
}

type PTPPerfSummaryProps = {
  id: string;
  intl: any;
  path: PTPPath;
  choices: PTPChoices;
  config: any;
  disabled: boolean;
  setModified: any;
};

export function WarningTab(props) {
  return (
    <>
      <Icon name="exclamation" color="red" />
      <span style={{ color: '#cc0000' }}>{props.children}</span>
    </>
  );
}

export function PTPPerformanceSummary(props: PTPPerfSummaryProps) {
  const { id } = useParams();
  const { path, intl } = props;
  const { formatMessage } = intl;
  const { isComplex, summaryLabels } = useLinkKind();

  const dispatch = useDispatch();
  const predictionModel = useSelector(
    (state: any) => state.mainFrame.predictionModel
  );
  let tabIndex = useSelector((state: any) => state.ptp.tabIndexes.performance);

  if (tabIndex >= summaryLabels.length) {
    tabIndex = 0;
    dispatch(syncPtpTabs({ key: 'performance', value: 0 }));
  }

  let worstEarthWarning: string = null;
  if (path.warnings != null && path.warnings.worst_earth?.length > 0) {
    worstEarthWarning = path.warnings.worst_earth;
  }

  if (path.warnings != null && path.warnings.diversity_warning?.length > 0) {
    worstEarthWarning = path.warnings.diversity_warning;
  }

  let component;
  if (!isComplex) {
    component = (
      <BasePTPPerformanceSummary
        id={id}
        LinkComponent={PTPLinkSummary}
        panelKind="ptp"
        {...props}
      />
    );
  } else {
    const numAvailableSummaries = Math.min(
      summaryLabels.length,
      path.summary.length
    );
    let panes = [...Array(numAvailableSummaries).keys()].map((i) => {
      let anyWarning =
        path.summary[i].link.min_payload_capacity != null &&
        path.summary[i].link.min_payload_capacity < 99.95;
      for (const endName of ['local', 'remote']) {
        anyWarning |= props.config[endName].fields.some((field) => {
          return field.warning != null
            ? !!field.warning({ path, pathIndex: i })
            : false;
        });
      }

      return {
        menuItem: (
          <Menu.Item>
            {anyWarning ? (
              <WarningTab>{summaryLabels[i]}</WarningTab>
            ) : (
              summaryLabels[i]
            )}
          </Menu.Item>
        ),
        render() {
          return (
            <Tab.Pane key={i}>
              <BasePTPPerformanceSummary
                id={id}
                LinkComponent={PTPLinkSummary}
                panelKind="ptp"
                pathIndex={i}
                {...props}
              />
            </Tab.Pane>
          );
        },
      };
    });

    if (panes.length < summaryLabels.length) {
      panes.push(
        ...[...Array(summaryLabels.length - panes.length).keys()].map((i) => {
          return {
            menuItem: (
              <Menu.Item disabled>
                <Popup
                  trigger={<p>{summaryLabels[panes.length + i]}</p>}
                  content={`${formatMessage(
                    additionalMessages.save
                  )} the current settings to view this tab`}
                />
              </Menu.Item>
            ),
          };
        })
      );
    }

    component = (
      <Tab
        panes={panes}
        defaultTabIndex={0}
        activeIndex={tabIndex}
        onTabChange={(_, data) => {
          dispatch(
            syncPtpTabs({ key: 'performance', value: data.activeIndex })
          );
        }}
      />
    );
  }

  return (
    <StoredAccordion
      name="ptp_performance_summary"
      title={`Performance Summary (${predictionModel})`}
    >
      {component}
      {worstEarthWarning != null ? (
        <Segment inverted color="red" tertiary>
          {worstEarthWarning}
        </Segment>
      ) : null}
    </StoredAccordion>
  );
}
