import React from 'react';
import { call, cancel } from 'redux-saga/effects';
import { FormattedMessage } from 'react-intl';
import { store } from './store';
import {
  tokenExpired,
  tokenRefresh,
  userLogout,
  warningMsg,
  uiSet,
  projectsLoaded,
  projectLoaded,
  unloadProject,
  recalculateProject,
  longTaskStarted,
} from './pages/mainframe/mainframe.reducer';
import config from './config';
import { featureCollection, lineString, point, polygon } from '@turf/helpers';
import { getAPCoords, flatPromise } from './utils/useful_functions';
import {
  fetchEquipmentConfigs,
  setZeroEquipmentConfigs,
} from './pages/equipment/equipment.reducer';
import { convertObjectKeys, toCamel } from './utils/change-case';
import { mergeClutterDetails } from './pages/project/EditClutterProperties';
import { fetchViewsheds } from './pages/viewshed/viewshed.reducer';
import { batch } from 'react-redux';
import { Message } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import {
  BEST_SERVER_PROGRESS_MESSAGE,
  BEST_SERVER_PROGRESS_SUBMESSAGE,
  ANP_PROGRESS_MESSAGE,
  ANP_PROGRESS_SUBMESSAGE,
  IS_DEV,
} from 'src/app.constants';
import about from './About';
import {
  startCalculation,
  stopCalculation,
} from './pages/best_server/best_server.reducer';
import {
  setSiteNameTemplate,
  DEFAULT_SITE_NAME_TEMPLATE,
} from 'src/pages/map/map.reducer';

// Max number of times to retry an endpoint if it returns a 409 CONFLICT
const LOCKED_MAX_RETRIES = 5;
const LOCKED_BASE_TIMEOUT = 1000;
const LOCKED_TIMEOUT_STEP = 1000;

function lockedNextTimeout(baseTimeout) {
  return baseTimeout + LOCKED_TIMEOUT_STEP;
}

export async function createItems(projectId, kind, data) {
  let response = null;
  try {
    response = await postWithAuth(`${kind}/${projectId}`, data);
  } catch (e) {
    console.error('Got error', e);
  }
  return response;
}

export async function deleteItems(projectId, kind, data) {
  let response = null;
  try {
    return await postWithAuth(`${kind}/${projectId}`, data, 'DELETE');
  } catch (e) {
    console.error('Got error', e);
  }
  return await response;
}

export async function newProject(postObj) {
  let response = null;
  try {
    response = await postWithAuth('project', postObj);
  } catch (e) {
    console.error('Got error', e);
    response = e;
  }
  return response;
}

export async function deleteProject(projectId) {
  let response = null;
  try {
    return await getWithAuth(`project/${projectId}`, 'DELETE');
  } catch (e) {
    console.error('Got error', e);
  }
  return response;
}

export function discardProjectChanges(projectId) {
  return getWithAuth(`project/${projectId}/work`, 'DELETE');
}

export async function saveAsProject(projectId, name) {
  let response = null;
  try {
    return await postWithAuth(
      `project/${projectId}/save_as`,
      { name: name },
      'PUT'
    );
  } catch (e) {
    console.error('Got error', e);
  }
  return response;
}

export async function cloneProject(projectId) {
  let response = null;
  try {
    return await postWithAuth(`project/${projectId}/clone`, {});
  } catch (e) {
    console.error('Got error', e);
  }
  return response;
}
export async function updatePreferencesApi(prefs) {
  try {
    return await postWithAuth(`account`, { ...prefs }, 'PATCH');
  } catch (e) {
    console.error('Got error', e);
    return Promise.reject(e);
  }
}

export async function updateAdminToolsApi(prefs) {
  try {
    return await postWithAuth(`admin/user`, { ...prefs }, 'PATCH');
  } catch (e) {
    console.error('Got error', e);
    return Promise.reject(e);
  }
}

const requestRefreshToken = (callback) => {
  return fetch(`${config.apiURL}/refresh`, {
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    credentials: 'include',
    method: 'GET',
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      const jwtToken = json.access_token;
      if (jwtToken) {
        callback?.(jwtToken);
        // used to call pending callbacks by sagas
        store.dispatch(tokenRefresh());
      } else {
        console.warn(json);
        if (!IS_DEV) {
          logoutUser();
        }
      }
    })
    .catch((err) => {
      console.log('refresh token failed');
      if (!IS_DEV) {
        logoutUser();
      }
    });
};

export function* refreshToken(action) {
  const { callback } = action.payload;
  yield requestRefreshToken(callback);
}

export const logoutUser = (e) => {
  e?.preventDefault();
  batch(() => {
    store.dispatch(userLogout());
    store.dispatch(setZeroEquipmentConfigs());
  });
  window.location = `${config.apiURL}/saml/logout`;
};

export const getApiVersion = () => {
  fetch(`${config.apiURL}/api_version`)
    .then((response) => {
      if (response && response.ok && response.status === 200) {
        response.text().then(function (text) {
          store.dispatch(uiSet({ apiVersion: text }));
        });
      }
    })
    .catch((err) => {
      console.log('Error from server');
    });
};
getApiVersion();

//saga listener for access token refreshed
export function tokenRefreshed() {
  pendingCalls.forEach((obj) => {
    obj.resolve();
  });
}

let refreshTokenInProgress = false;
const pendingCalls = [];
export function renewTokens(callback) {
  // If refresh token call in progress
  // then add the requests to queue and resolve
  // then once refresh call is fulfilled
  if (refreshTokenInProgress) {
    const promise = flatPromise();
    pendingCalls.push({
      ...promise,
    });
    return promise.promise.then((res) => {
      return callback();
    });
  } else {
    refreshTokenInProgress = true;
    const tokenPromise = new Promise((resolve) => {
      store.dispatch(tokenExpired({ callback: resolve }));
    });
    return tokenPromise.then((res) => {
      refreshTokenInProgress = false;
      return callback();
    });
  }
}

function handleLockedRetries(callback, retries, baseTimeout) {
  // resource was locked...
  if (retries === 0) {
    // tried too many times, let the user know it was cancelled
    toast(
      <Message error>
        Unable to complete that action. Please try once existing calculations
        have finished.
      </Message>
    );
    return Promise.resolve(
      'The operation was blocked by existing calculations'
    );
  } else {
    // retry it
    if (retries === LOCKED_MAX_RETRIES) {
      // show a toast for the first time the api call got blocked
      toast(
        <Message>
          Your action was delayed due to existing calculations. Retrying...
        </Message>
      );
    }

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        callback()
          .then((res) => {
            return resolve(res);
          })
          .catch((err) => {
            return reject(err);
          });
      }, lockedNextTimeout(baseTimeout));
    });
  }
}

export async function getWithAuth(
  url,
  method = 'GET',
  isBinaryResponse = false,
  retries = LOCKED_MAX_RETRIES,
  baseTimeout = LOCKED_BASE_TIMEOUT
) {
  const results = await fetch(`${config.apiURL}/${url}`, {
    // credentials: 'include',
    // mode: 'cors',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    method: method,
    credentials: 'include',
  })
    .then((response) => {
      if (response) {
        switch (response.status) {
          case 200:
            return isBinaryResponse ? response.blob() : response.json();
          case 400:
            return Promise.reject({
              detail: 'Unable to find report with above email',
            });
          case 401:
            return renewTokens(() =>
              getWithAuth(url, method, isBinaryResponse, retries, baseTimeout)
            );
          case 404:
            return Promise.reject({ detail: 'Incorrect endpoint' });
          case 409:
            // resource was locked...
            return handleLockedRetries(
              () => {
                const newTimeout = lockedNextTimeout(baseTimeout);
                return getWithAuth(
                  url,
                  method,
                  isBinaryResponse,
                  retries - 1,
                  newTimeout
                );
              },
              retries,
              baseTimeout
            );
          case 500:
            return Promise.reject('server error');
          case 503:
            return Promise.reject('server unavailable');
          default:
            return Promise.reject('Unexpected response');
        }
      } else {
        return Promise.reject('No response received');
      }
    })
    .catch((err) => {
      console.error('Error from server', err);
      return Promise.reject(err);
    });
  return results;
}

function HTTP_422_UNPROCESSABLE_ENTITY() {
  this.name = 'Unprocessable Content';
  this.message = '422 - Unprocessable Content. Check schema.';
}

HTTP_422_UNPROCESSABLE_ENTITY.prototype = new Error();

export async function postWithAuth(
  url,
  body,
  method = 'POST',
  retries = LOCKED_MAX_RETRIES,
  baseTimeout = LOCKED_BASE_TIMEOUT
) {
  // NOTE: The url should not end with "/" when the method is POST or PATCH
  // since it can cause the request to be blocked as "mixed active content"
  return await fetch(`${config.apiURL}/${url}`, {
    // credentials: 'include',
    // mode: 'cors',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    method: method,
    credentials: 'include',
    body: JSON.stringify(body),
  })
    .then((response) => {
      if (response && response.status === 204 && method === 'DELETE') {
        return true;
      }
      let json = response.json();
      if (response && response.status >= 200 && response.status < 300) {
        return json;
      } else if (response && response.status === 401) {
        return renewTokens(() =>
          postWithAuth(url, body, method, retries, baseTimeout)
        );
      } else if (response && response.status === 409) {
        // resource was locked...
        return handleLockedRetries(
          () => {
            const newTimeout = lockedNextTimeout(baseTimeout);
            return postWithAuth(url, body, method, retries - 1, newTimeout);
          },
          retries,
          baseTimeout
        );
      } else if (response && response.status === 422) {
        return Promise.reject(new HTTP_422_UNPROCESSABLE_ENTITY());
      } else {
        return json.then(Promise.reject.bind(Promise));
      }
    })
    .catch((err) => {
      console.error('Error from server', err);
      return Promise.reject(err);
    });
}

export async function postFileWithAuth(url, body, method = 'POST') {
  return await fetch(`${config.apiURL}/${url}`, {
    // DO NOT SET CONTENT TYPE WHEN SENDING FILES WITH FETCH!
    // https://muffinman.io/uploading-files-using-fetch-multipart-form-data/
    method,
    credentials: 'include',
    body,
  })
    .then((response) => {
      if (response && response.ok && response.status === 200) {
        return response.json();
      } else if (response && response.status === 422) {
        return Promise.reject('Invalid file');
      } else if (response && response.status === 401) {
        return renewTokens(() => postFileWithAuth(url, body, method));
      } else if (response && response.status === 400) {
        return response.json();
      }
    })
    .catch((err) => {
      console.error('Error from server');
      return Promise.reject(err);
    });
}

/*
 * Return a promise to fetch an array of projects for the current user
 */
// export async function getProjects() {
//   const projects = await getWithAuth('projects');
//   return projects.objects;
// }

export const sortProjects = (p1, p2) => {
  const d1 = Date.parse(p1.last_accessed);
  const d2 = Date.parse(p2.last_accessed);
  return d2 - d1;
};
/*
 * Generator to load projects for the current user
 */
export function* getProjects() {
  try {
    const projects = yield call(getWithAuth, 'projects');
    //   console.log(`Got ${projects.count} projects`);
    if (projects) {
      // Sort the projects so that the recently accessed projects
      // are at the start of the list
      projects.sort(sortProjects);

      yield store.dispatch(projectsLoaded(projects));
    } else {
      //   console.log('Projects - force token refresh');
      //   yield dispatchAction(tokenExpired);
      //   yield call(getProjects);
    }
  } catch (e) {
    console.log('Got error', e);
    yield cancel();
  }
}

export function* getProjectGeoJson(action) {
  const { projectId } = action.payload;
  try {
    console.time('Geom load');
    if (projectId) {
      // Forces the project modified time to update
      // and creates the in-work version of the project
      const project = yield getWithAuth(`project/${projectId}`);
      const inworkID = project.id;
      const permissions = yield getWithAuth(`project/${inworkID}/permissions`);

      let permissionRead = true;
      let permissionWrite = false;
      let permissionAdmin = false;
      if (permissions === 2) {
        // readwrite
        permissionWrite = true;
      } else if (permissions === 3) {
        // admin
        permissionWrite = true;
        permissionAdmin = true;
      }

      const networkSites = yield call(loadGeom, inworkID, 'network_site');
      const subscriberSites = yield call(loadGeom, inworkID, 'subscriber_site');
      const pmpLinks = yield call(loadGeom, inworkID, 'pmp_link');
      const meshLinks = yield call(loadGeom, inworkID, 'mesh_link');
      // const pmpLinks = linkFeatureCollection('pmp_link', []);
      const accessPoints = yield call(loadGeom, inworkID, 'access_point');

      const ptpLinks = yield call(loadGeom, inworkID, 'ptp_link');
      //const ptpLinks = linkFeatureCollection('ptp_link', []);

      // reset / update state of various features
      yield store.dispatch(fetchEquipmentConfigs(inworkID));
      yield store.dispatch(fetchViewsheds(inworkID));

      if (
        networkSites &&
        subscriberSites &&
        ptpLinks &&
        pmpLinks &&
        meshLinks &&
        accessPoints
      ) {
        let batchActions = [];

        batchActions.push([
          projectLoaded,
          {
            projectId: inworkID,
            projectName: project.name,
            projectRefId: project.ref_id,
            projectLoaded: true,
            projectModified: project.modified,
            terragraphImport: project.terragraph_import,
            clutterDetails: mergeClutterDetails(project.clutter),
            predictionModel: project.prediction_model,
            useClutter: project.use_clutter,
            generalProjectProps: project.data
              ? convertObjectKeys(project.data, toCamel)
              : null,
            permissionRead,
            permissionWrite,
            permissionAdmin,
            networkSites,
            subscriberSites,
            ptpLinks,
            pmpLinks,
            meshLinks,
            accessPoints,
          },
        ]);

        // Update site name templates map store values based from API data
        batchActions.push([
          setSiteNameTemplate,
          {
            isNetwork: true,
            value:
              project.network_site_name_template ||
              DEFAULT_SITE_NAME_TEMPLATE.NETWORK,
          },
        ]);
        batchActions.push([
          setSiteNameTemplate,
          {
            isNetwork: false,
            value:
              project.subscriber_site_name_template ||
              DEFAULT_SITE_NAME_TEMPLATE.SUBSCRIBER,
          },
        ]);

        let warningObj = null;
        if (!permissionWrite) {
          warningObj = {
            heading: (
              <FormattedMessage
                id="general.writeDisabled"
                defaultMessage="Project write disabled"
              />
            ),
            message: (
              <FormattedMessage
                id="general.readOnlyWarning"
                defaultMessage="You only have read access for this project"
              />
            ),
            allowClose: true,
            warning: true,
          };
        }
        batchActions.push([warningMsg, warningObj]);

        const projectVersion = project.version;
        const forceRecalc =
          projectVersion !==
            `${window.runtime.version}-${window.runtime.changeset}` ||
          project.data?.needs_recalc;
        if (forceRecalc) {
          batchActions.push([recalculateProject, { start: true }]);
        }
        if (permissionWrite) {
          // check whether best server is currently in progress
          const inProgress = yield getWithAuth(
            `project/${inworkID}/best_server/in_progress`
          );
          if (inProgress.status) {
            batchActions.push([startCalculation]);
            batchActions.push([
              longTaskStarted,
              {
                heading: BEST_SERVER_PROGRESS_MESSAGE,
                message: BEST_SERVER_PROGRESS_SUBMESSAGE,
              },
            ]);
            const navigate = store.getState().mainFrame.navigate;
            if (navigate) {
              navigate('/best_server');
            }
          } else {
            batchActions.push([stopCalculation]);
          }

          // check whether ANP is currently in progress
          const anpStatus = yield getWithAuth(
            `projects/${inworkID}/anp/schedule/status`
          ).catch(() => null);
          if (
            anpStatus != null &&
            (anpStatus.status === 'started' || anpStatus.status === 'ready')
          ) {
            batchActions.push([
              longTaskStarted,
              {
                heading: ANP_PROGRESS_MESSAGE,
                message: ANP_PROGRESS_SUBMESSAGE,
              },
            ]);
            const navigate = store.getState().mainFrame.navigate;
            if (navigate) {
              navigate('/terragraph_planner');
            }
          }
        }

        batch(() => {
          batchActions.forEach((i) => {
            const [action, arg] = i;
            store.dispatch(action(arg));
          });
        });

        if (forceRecalc) {
          try {
            // api version has changed, full project recalc required
            const doRecalc = yield postWithAuth(
              `project/${inworkID}/recalculate`,
              {}
            );
          } catch (e) {
            console.error(e);
          }
        }
      } else {
        console.log('Force token refresh');
        yield store.dispatch(tokenExpired());
        yield call(getProjectGeoJson, action);
      }
    }
    console.timeEnd('Geom load');
  } catch (e) {
    store.dispatch(uiSet({ loadingProject: false }));
    store.dispatch(unloadProject());

    const subject = encodeURIComponent('Unable to load project');
    const body = encodeURIComponent(`Project id: ${projectId}`);
    toast(
      <Message error>
        Error loading project with id {projectId}. Please email this id to{' '}
        <a href={`mailto:${about.email}?subject=${subject}&body=${body}`}>
          {about.email}
        </a>
      </Message>,
      {
        autoClose: 30000,
        draggable: false,
        closeOnClick: false,
        className: 'cursor-auto',
      }
    );
    return cancel();
  }
}

export function* getProject(action) {
  const { projectId } = action.payload;
  if (projectId) {
    const projectData = yield call(loadProject, projectId);

    if (projectData) {
      const { name, use_clutter, prediction_model, data, clutter } =
        projectData;
      store.dispatch(
        uiSet({
          projectName: name,
          useClutter: use_clutter,
          predictionModel: prediction_model,
          clutterDetails: mergeClutterDetails(clutter),
          generalProjectProps: convertObjectKeys(data, toCamel),
        })
      );
    } else {
      console.log('Force token refresh');
      store.dispatch(tokenExpired());
      //   call(getProject, projectId);
      yield call(getProject, action);
    }
  }
}

async function saveProject(options, data) {
  const { projectId } = options;
  if (projectId) {
    let response = null;
    try {
      response = await postWithAuth(`project/${projectId}`, data, 'PATCH');
    } catch (e) {
      console.log('Got error', e);
      return cancel();
    }
    return response;
  }
}

export async function savePermissions(options, data, method = 'POST') {
  const { projectId } = options;
  if (projectId) {
    let response = null;
    try {
      response = await postWithAuth(
        `project/${projectId}/permissions`,
        data,
        method
      );
    } catch (e) {
      console.log('Got error', e);
      return Promise.reject(e);
    }
    return response;
  }
}

export async function updatePermissions(options, data) {
  return await savePermissions(options, data, 'PUT');
}

export async function deletePermissions(options, data) {
  return await savePermissions(options, data, 'DELETE');
}

export async function saveSite(options, data) {
  const { id, projectId } = options;
  if (projectId) {
    let response = null;
    try {
      response = await postWithAuth(
        `project/${projectId}/site/${id}`,
        data,
        'PATCH'
      );
    } catch (e) {
      console.log('Got error', e);
      return cancel();
    }
    return response;
  }
}

export async function saveLink(options, data) {
  const { id, kind, projectId } = options;
  if (projectId) {
    let response = null;
    try {
      response = await postWithAuth(`${kind}/${projectId}/${id}`, data);
    } catch (e) {
      console.log('Got error', e);
      return cancel();
    }
    return response;
  }
}

const postFunctions = {
  project: saveProject,
  network_site: saveSite,
  subscriber_site: saveSite,
  ptp_link: saveLink,
  access_point: saveLink,
};

export function* saveChanges(action) {
  const { kind, options, data } = action.payload;
  const func = postFunctions[kind];
  if (func) {
    const response = yield call(func, options, data);
    if (response) {
      console.info(response);
    } else {
      console.log('Force token refresh');
      store.dispatch(tokenExpired());
      yield call(saveChanges, action);
    }
  } else {
    console.warn(`Unable to save ${kind}`);
  }
}

async function loadProject(projectId) {
  if (projectId) {
    try {
      return await getWithAuth(`project/${projectId}`);
    } catch (e) {
      console.log('Got error', e);
      return cancel();
    }
  }
}

export async function loadGeom(projectId, kind) {
  if (projectId) {
    try {
      switch (kind) {
        case 'network_site':
          return siteFeatureCollection(
            kind,
            await getWithAuth(`project/${projectId}/sites/network/geometry`)
          );
        case 'subscriber_site':
          return siteFeatureCollection(
            kind,
            await getWithAuth(`project/${projectId}/sites/subscriber/geometry`)
          );
        case 'ptp_link':
          return linkFeatureCollection(
            kind,
            await getWithAuth(`project/${projectId}/ptp/geometry`)
          );
        case 'pmp_link':
          return linkFeatureCollection(
            kind,
            await getWithAuth(`project/${projectId}/subscribers/geometry`)
          );
        case 'mesh_link':
          return linkFeatureCollection(
            kind,
            await getWithAuth(`project/${projectId}/mesh_links/geometry`)
          );
        case 'access_point':
          return accessPointFeatureCollection(
            kind,
            await getWithAuth(`project/${projectId}/access_points/geometry`)
          );
        default:
          console.warn(`Don't know how to handle ${kind}`);
          return featureCollection([]);
      }
    } catch (e) {
      console.log('Got error', e);
      return cancel();
    }
  }
}

export const siteFeatureCollection = (kind, geomList) => {
  const features = geomList.map((row) => {
    const geom = point([row.longitude, row.latitude], { kind: kind, ...row });
    geom.id = `${kind}-${row.id}`;
    return geom;
  });

  return featureCollection(features);
};

export const linkFeatureCollection = (kind, geomList) => {
  const features = geomList.map((row) => {
    const geom = lineString(
      [
        [row.loc_lng, row.loc_lat],
        [row.rem_lng, row.rem_lat],
      ],
      { kind: kind, ...row }
    );
    geom.id = `${kind}-${row.id}`;
    return geom;
  });

  return featureCollection(features);
};

export const accessPointFeatureCollection = (
  kind,
  geomList,
  smRange = null,
  rangeUnits = null
) => {
  let features = [];
  for (const ap of geomList) {
    for (const coords of getAPCoords(ap, smRange, rangeUnits)) {
      // TODO: associate the radio and antenna IDs with each shape
      const geom = polygon([coords], {
        kind: kind,
        ...ap,
      });
      geom.id = `${kind}-${ap.id}`;

      //   const firstPoint = coords[0];
      //   if (
      //     (firstPoint[0] !== ap.longitude || firstPoint[1] !== ap.latitude) &&
      //     // Omni antennas should have 181 points
      //     coords.length <= 180
      //   ) {
      //     // AP is offset from the site
      //     const combinedFeature = geometryCollection([
      //       geom.geometry,
      //       lineString([[ap.longitude, ap.latitude], firstPoint]).geometry,
      //     ]);

      //     combinedFeature.properties = geom.properties;
      //     features.push(combinedFeature);
      //   } else {
      features.push(geom);
      //   }
    }
  }
  return featureCollection(features);
};

export async function getUserSelectedColumns(table_id) {
  let response = null;
  try {
    return await getWithAuth(`user/table/${table_id}`, 'GET');
  } catch (e) {
    console.error('Got error', e);
  }
  return response;
}

export async function setUserSelectedColumns(table_id, columns) {
  let response = null;
  try {
    return await postWithAuth(
      `user/table/${table_id}`,
      { columns: columns },
      'POST'
    );
  } catch (e) {
    console.error('Got error', e);
  }
  return response;
}
