import additionalMessages from '../messages';
import { scaleLinear } from 'd3-scale';
import { radioHeight } from './P526_10';
import { last, meterToAny } from './useful_functions';

//Returns the radioheights for given path length
export const calcRadioHeights = (ranges: number[]): number[] => {
  const yOffSets: number[] = [];
  let xTicks = [];
  const pathLength = ranges[ranges.length - 1];
  xTicks = scaleLinear()
    .domain([ranges[0], last(ranges)])
    .ticks();

  if (last(xTicks) !== pathLength) {
    xTicks.push(pathLength);
  }

  xTicks.forEach((tick) => {
    yOffSets.push(radioHeight(pathLength, tick));
  });
  return yOffSets;
};

export const getLabelsByPrefs = (
  prefs: { rangeUnits: string; heightUnits: string },
  formatMessage: any
) => {
  const retVal = {
    lengthLbl: formatMessage(additionalMessages.kilometers),
    heightLbl: formatMessage(additionalMessages.meters),
  };
  if (prefs.rangeUnits === 'mi') {
    retVal['lengthLbl'] = formatMessage(additionalMessages.miles);
  }
  if (prefs.heightUnits === 'ft') {
    retVal['heightLbl'] = formatMessage(additionalMessages.feet);
  }
  return retVal;
};

export const range = (start: number, stop: number, step = 1) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

export const calculateTicks = (domain: number[]) => {
  return scaleLinear().domain(domain).ticks();
};

export const getMinMaxOfSeries = (series: any[]): number[] => {
  // Need to find the min and max y values
  // to get the y axis ticks from d3 scale
  // and plot curved grid lines
  let lowest = Number.POSITIVE_INFINITY,
    highest = Number.NEGATIVE_INFINITY,
    tmp;
  for (let i = series.length - 1; i >= 0; i--) {
    tmp = series[i].y;
    if (tmp < lowest) lowest = tmp;
    if (tmp > highest) highest = tmp;
  }
  return [lowest, highest];
};

// Returns sight y coordinate for given xposition
// local & remote antenna heights
export const getSightLine = ({
  xpos,
  lAntennaHeight,
  rAntennaHeight,
  pathLength,
}: any): number => {
  return (
    lAntennaHeight + xpos * ((rAntennaHeight - lAntennaHeight) / pathLength)
  );
};

// Returns the precision for the given path length and units
// expect pathlength in meters and units km or miles
export const calcPrecision = (pathLength: number, units: string) => {
  const pathLengthConverted = meterToAny(pathLength, units, 0, false);
  let precision = 0;
  if (pathLengthConverted <= 0.5) {
    precision = 3;
  } else if (pathLengthConverted < 1) {
    precision = 2;
  } else if (pathLengthConverted < 20) {
    precision = 1;
  }
  return precision;
};

export function clone<T>(instance: T): T {
  const copy = new (instance.constructor as { new (): T })();
  Object.assign(copy, instance);
  return copy;
}
