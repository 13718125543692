import get from 'lodash/get';
import React, { CSSProperties, useEffect } from 'react';
import {
  Controller,
  ControllerRenderProps,
  FieldValues,
  useFormContext,
} from 'react-hook-form';
import { Button, Form, FormFieldProps, Label, Popup } from 'semantic-ui-react';
import { PTPChoices } from 'src/pages/ptp/utils';
import {
  anyToMeter,
  decodeLatLong,
  encodeLatLong,
  formatMacAddress,
  getUnitsLabels,
  isLatLng,
  isMacAddress,
  meterToAny,
  pyround,
} from 'src/utils/useful_functions';
import { scaleAndFix, scaleValue } from './ScaledField';
import messages from 'src/messages';
import { useSelector } from 'react-redux';

type GenericProps = Omit<ControllerRenderProps<FieldValues, string>, 'ref'> & {
  label?: string | JSX.Element;
  value: string;
  units?: string;
  disabled: boolean;
  // The control will be disabled when the user does
  // not have write permission on the project, but there
  // are some controls where we want to ignore this behaviour
  // and allow the user to edit the control value even with read-only
  // permission.
  alwaysEnabled: boolean;
  hoverMessage?: string;
  setText: React.Dispatch<React.SetStateAction<string | null>>;
  setModified?: React.Dispatch<React.SetStateAction<boolean>>;
  unitTooltip?: (arg: { choices: PTPChoices }) => string | null;
  unitExtraTooltip?: (arg: { units: string }) => string | null;
  choices?: PTPChoices;
  textColor?: 'red';
  style?: CSSProperties;
  onChange?: Function;
  onAcceptChange?: Function;
  recalc?: boolean;
  extraProps?: Record<string, any>;
  children?: any;
} & FormFieldProps;

function ScaledFieldImpl(props: GenericProps) {
  const {
    setValue,
    trigger,
    formState: { errors },
  } = useFormContext();
  const permissionWrite = useSelector(
    (state: any) => state.mainFrame.permissionWrite
  );
  const isMac = isMacAddress(props.units);
  const isCoords = isLatLng(props.units);
  const error = get(errors, props.name);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    props.setText(value);
    setValue(
      props.name,
      scaleValue(value, props.units, 'store', false, props.extraProps),
      {
        shouldDirty: true,
        shouldValidate: true,
      }
    );
    if (props.setModified != null) {
      props.setModified(true);
    }
    props.onChange(value);
  };

  let classes = props.className != null ? [props.className] : [];
  if (props.disabled != null && props.disabled) {
    classes.push('no-border');
  }
  if (props.textColor === 'red') {
    classes.push('red-input');
  }
  let unitExtraTooltip: any = props.unitExtraTooltip;
  if (typeof unitExtraTooltip === 'function' && props.units != null) {
    unitExtraTooltip = unitExtraTooltip({ units: props.units });
  }
  return (
    <Form.Field className={props.recalc ? 'end-attr-recalc' : ''}>
      <Popup
        content={messages.saveChanges.defaultMessage}
        basic
        disabled={!props.recalc}
        trigger={props.label != null ? <label>{props.label}</label> : null}
      />
      <Form.Group>
        <Form.Input
          type={
            isMac || isCoords || props.units === 'msn' || props.value === '---'
              ? 'text'
              : 'number'
          }
          step="any"
          error={error != null}
          onChange={handleChange}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              if (props.onAcceptChange) {
                props.onAcceptChange(e);
              }
            }
          }}
          value={props.value}
          disabled={
            !props.alwaysEnabled && (props.disabled || !permissionWrite)
          }
          width={props.width}
          title={props.hoverMessage}
          className={classes.join(' ') || null}
          style={props.style}
          onBlur={(e) => {
            if (props.onAcceptChange) {
              props.onAcceptChange(e);
            }
          }}
        />
        {props.units != null &&
          !isMac &&
          !isCoords &&
          props.units !== 'msn' && (
            <Label>
              {getUnitsLabels(props.units)}
              {props.unitTooltip != null && props.choices != null
                ? props.unitTooltip({ choices: props.choices })
                : null}
            </Label>
          )}
        {unitExtraTooltip != null && (
          <Label color={props.recalc ? 'blue' : ''} basic>
            {unitExtraTooltip}
          </Label>
        )}

        {props.buttonConfig?.show && (
          <Button
            color="blue"
            className="ml-1"
            type="button"
            onClick={() => props.buttonConfig?.onClick()}
          >
            {props.buttonConfig.name}
          </Button>
        )}
        {props.children}
      </Form.Group>
    </Form.Field>
  );
}

type Props = {
  label?: string | JSX.Element;
  getter: string;
  defaultValue: number;
  min?: number;
  max?: number;
  units?: string;
  precision?: number;
  minPrecision?: number;
  maxPrecision?: number;
  disabled?: boolean;
  // The control will be disabled when the user does
  // not have write permission on the project, but there
  // are some controls where we want to ignore this behaviour
  // and allow the user to edit the control value even with read-only
  // permission.
  alwaysEnabled: boolean;
  setModified?: React.Dispatch<React.SetStateAction<boolean>>;
  watch?: any;
  hoverMessage?: string;
  unitTooltip?: any;
  unitExtraTooltip?: any;
  choices?: PTPChoices;
  textColor?: 'red';
  style?: CSSProperties;
  recalc?: boolean;
  onChange?: Function;
  onAcceptChange?: Function;
  // extraProps is used to pass any extra attributes from config like
  // isLat in case of latitude and longitude
  extraProps?: Record<string, any>;
  children?: any;
} & FormFieldProps;

export function GenericScaledField(props: Props) {
  const {
    label,
    getter,
    defaultValue,
    min,
    max,
    units,
    precision: initialPrecision = null,
    watch,
    disabled,
    alwaysEnabled,
    hoverMessage,
    unitTooltip,
    unitExtraTooltip,
    choices,
    textColor,
    recalc,
    onChange,
    onAcceptChange,
    extraProps = {},
    children,
  } = props;

  const precision = initialPrecision ?? 0;
  const { minPrecision = precision, maxPrecision = precision } = props;

  const { control, getValues } = useFormContext();

  const [text, setText] = React.useState<string | null>(null);

  useEffect(() => {
    // TODO add other units as necessary
    if (units === 'ft') {
      setText(
        meterToAny(
          text != null ? parseFloat(text) : defaultValue,
          units,
          precision ?? 1
        ).toString()
      );
    } else if (units === 'm' && text != null) {
      setText(anyToMeter(parseFloat(text), 'ft', precision ?? 1).toString());
    } else if (units === 'msn') {
      setText(text ? text : defaultValue);
    } else if (isMacAddress(units)) {
      setText(formatMacAddress(text ?? defaultValue, units));
    } else if (isLatLng(units)) {
      setText(encodeLatLong(text ?? defaultValue, units, extraProps.isLat));
    }
  }, [units]);

  useEffect(() => {
    setText(null);
  }, [JSON.stringify(watch)]);

  let rules: any;
  let precisionCheck: boolean = false;

  if (isMacAddress(units)) {
    rules = {
      required: false,
      validate: {
        mac: (v) => {
          return (
            v == null ||
            v.length === 0 ||
            /^(\d|[a-f]){12}$/.test(v.replace(/[:\-.]/g, '').toLowerCase())
          );
        },
      },
    };
  } else if (isLatLng(units)) {
    rules = {
      required: true,
      validate: {
        latLng: (v) => {
          return decodeLatLong(v, extraProps.isLat)[1];
        },
      },
    };
  } else if (units === 'msn') {
    rules = {
      required: false,
      validate: {
        msn: (v) => {
          return v == null || v.length === 0 || /^[a-zA-Z0-9]{10,12}$/.test(v);
        },
      },
    };
  } else if (units === 'MHz' && label === 'Tx Frequency') {
    rules = { min, max, required: false };
  } else if (disabled) {
    rules = { required: false };
  } else {
    precisionCheck = true;
    rules = { min, max, required: true };
  }
  // this rule is to not allow decimal value
  // when precision is set to 0
  if (precisionCheck && initialPrecision === 0) {
    if (rules.validate == null) {
      rules.validate = {};
    }
    rules.validate.precision = (value) =>
      Math.ceil(value) === Math.floor(value);
  }
  const formattedMin = pyround(
    meterToAny(min, units, precision, false),
    precision
  ).toFixed(minPrecision);
  const formattedMax = pyround(
    meterToAny(max, units, precision, false),
    precision
  ).toFixed(maxPrecision);
  let hoverText = hoverMessage;
  if (hoverText == null && min != null && max != null) {
    hoverText = `Range ${formattedMin} to ${formattedMax}`;
    if (units != null) {
      hoverText = `Range ${formattedMin}${units} to ${formattedMax}${units}`;
    }
  }

  function onChangeFn(e) {
    onChange && onChange(e);
  }

  function doScaling(formValue) {
    let value;
    if (props.computedValue) {
      // 'computed' value takes precedence over others
      value = props.computedValue(formValue, getValues);
    } else if (text != null) {
      return text;
    } else {
      value = formValue;
    }

    return scaleAndFix(value, units, precision, 'show', false, extraProps);
  }

  return (
    <Controller
      control={control}
      name={getter}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field: { ref, ...rest } }) => {
        return (
          <ScaledFieldImpl
            {...rest}
            label={label}
            value={doScaling(rest.value)}
            units={units}
            disabled={disabled ?? false}
            alwaysEnabled={alwaysEnabled ?? false}
            setText={setText}
            setModified={props.setModified}
            choices={choices}
            hoverMessage={hoverText}
            unitTooltip={unitTooltip}
            unitExtraTooltip={unitExtraTooltip}
            textColor={textColor}
            style={props.style}
            className={props.className}
            recalc={recalc}
            onChange={(e) => onChangeFn(e)}
            onAcceptChange={onAcceptChange}
            extraProps={extraProps}
            width={props.width}
          >
            {children}
          </ScaledFieldImpl>
        );
      }}
    />
  );
}
