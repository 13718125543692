import React, { useEffect, useState } from 'react';
import {
  Dimmer,
  Message,
  Button,
  Loader,
  Form,
  Icon,
  Divider,
} from 'semantic-ui-react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { getWithAuth, postWithAuth } from '../../api';
import additionalMessages from '../../messages';
import SiteDetailsPanel from './SiteDetailsPanel';
import { store } from '../../store';
import { injectIntl } from 'react-intl';
import { fetchSiteGraph } from './site.reducer';
import { useSelector } from 'react-redux';
import NetworkSiteBomModal from 'src/pages/sites/NetworkSiteBomModal';
import { downloadReport } from '../../utils/useful_functions';
import { fetchSites, uiConfirmAction } from '../mainframe/mainframe.reducer';
import { PanelHeading, ToolbarSeparator } from 'src/components/PanelHeading';

export const HideButtonContext = React.createContext(null);

const plusOffset = {
  position: 'relative',
  left: '8px',
  top: '-4px',
};

const iconMargin = {
  marginLeft: '6px',
  marginRight: '6px',
  marginTop: '-1px',
  marginBottom: '-1px',
};

/*
 * Network Site toolbar buttons
 */
function NetworkSiteToolbar(props) {
  const {
    formatMessage,
    hideButton,
    hasTowerSwitch,
    setHasTowerSwitch,
    graph,
    siteId,
    currentSite,
    projectId,
  } = props;
  const permissionWrite = useSelector(
    (state) => state.mainFrame.permissionWrite
  );
  const [installReportLoading, setInstallReportLoading] = useState(false);
  const [salesReportLoading, setSalesReportLoading] = useState(false);
  const [listViewShowTowerSwitch, setListViewShowTowerSwitch] = useState({
    status: false,
    state: '',
  });

  return (
    <>
      {listViewShowTowerSwitch.status && (
        <NetworkSiteBomModal
          objId={siteId}
          kind="network_site"
          url={`project/${projectId}/site/${siteId}/bom`}
          tsurl={`project/${projectId}/site/${siteId}/tower_switch`}
          listViewShowTowerSwitch={listViewShowTowerSwitch}
          listViewResetShowTowerSwitch={(value) => {
            setListViewShowTowerSwitch({ status: value, state: '' });
            setHasTowerSwitch(true);
          }}
        />
      )}

      {hasTowerSwitch ? (
        <Button
          icon
          basic
          compact
          title="Edit the tower switch configuration"
          disabled={!permissionWrite}
          onClick={(event, data) => {
            setListViewShowTowerSwitch({ status: true, state: 'edit' });
          }}
        >
          <Icon.Group size="large" style={iconMargin}>
            <Icon name="pencil" size="tiny" style={plusOffset} color="blue" />
            <Icon className="tower-switch-icon" />
          </Icon.Group>
        </Button>
      ) : (
        <Button
          icon
          basic
          compact
          title="Add a tower switch to this site"
          disabled={!permissionWrite}
          onClick={(event, data) => {
            setListViewShowTowerSwitch({ status: true, state: 'add' });
          }}
        >
          <Icon.Group size="large" style={iconMargin}>
            <Icon name="plus" size="tiny" style={plusOffset} color="blue" />
            <Icon className="tower-switch-icon" />
          </Icon.Group>
        </Button>
      )}
      <Button
        icon
        basic
        compact
        title="Delete the tower switch from this site"
        onClick={(event, data) => {
          store.dispatch(
            uiConfirmAction({
              header: 'Delete Tower Switch',
              message: 'Are you sure?',
              size: 'mini',
              onConfirm: () => {
                postWithAuth(
                  `project/${projectId}/site/${currentSite.id}/tower_switch`,
                  {},
                  'DELETE'
                )
                  .then(() => {
                    setListViewShowTowerSwitch({
                      status: false,
                      state: 'delete',
                    });
                    setHasTowerSwitch(false);
                  })
                  .catch((err) => {});
              },
            })
          );
        }}
        disabled={!hasTowerSwitch || !permissionWrite}
      >
        <Icon.Group size="large" style={iconMargin}>
          <Icon name="delete" size="tiny" style={plusOffset} color="blue" />
          <Icon className="tower-switch-icon" />
        </Icon.Group>
      </Button>
      <NetworkSiteBomModal
        objId={siteId}
        kind="network_site"
        url={`project/${projectId}/site/${siteId}/bom`}
        disabled={hideButton}
        name={currentSite?.name}
      />
      <Button
        icon
        basic
        compact
        loading={salesReportLoading}
        title={`PMP ${formatMessage(additionalMessages.salesReport)}`}
        disabled={graph.aps?.length === 0 || hideButton}
        onClick={() => {
          setSalesReportLoading(true);
          getWithAuth(`project/${projectId}/reports/site/${siteId}/proposal`)
            .then((repId) => {
              downloadReport(
                getWithAuth,
                projectId,
                repId,
                `Site_${currentSite?.name}_PMP_Proposal_Report`
              ).finally(() => {
                setSalesReportLoading(false);
              });
            })
            .catch(() => {
              setSalesReportLoading(false);
            });
        }}
      >
        <Icon className="proposal-report-icon" size="large" />
      </Button>
      <Button
        icon
        basic
        compact
        loading={installReportLoading}
        title={`PMP ${formatMessage(additionalMessages.installationReport)}`}
        disabled={graph.aps?.length === 0 || hideButton}
        onClick={() => {
          setInstallReportLoading(true);
          getWithAuth(
            `project/${projectId}/reports/site/${siteId}/installation`
          )
            .then((repId) => {
              downloadReport(
                getWithAuth,
                projectId,
                repId,
                `Site_${currentSite?.name}_PMP_Installation_Report`
              ).finally(() => {
                setInstallReportLoading(false);
              });
            })
            .catch(() => {
              setInstallReportLoading(false);
            });
        }}
      >
        <Icon className="installation-report-icon" size="large" />
      </Button>
    </>
  );
}

const SitePanel = (props) => {
  const { pathname } = useLocation();
  const path = pathname.includes('network_site')
    ? '/network_sites'
    : '/subscriber_sites';
  const panelKind = pathname.includes('network_site')
    ? 'network_site'
    : 'subscriber_site';
  const { formatMessage } = props.intl;
  const [projectId, permissionWrite] = useSelector((state) => [
    state.mainFrame.projectId,
    state.mainFrame.permissionWrite,
  ]);
  const { siteId } = useParams();
  const [currentSite, setCurrentSite] = useState({});
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const { graph } = useSelector((state) => state.sites);
  const [hideButton, setHideButton] = useState(false);
  const [hasTowerSwitch, setHasTowerSwitch] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (currentSite) setHasTowerSwitch(currentSite.has_tower_switch);
  }, [currentSite]);

  useEffect(() => {
    getWithAuth(`project/${projectId}/site/${siteId}`)
      .then((data) => {
        setCurrentSite(data);
      })
      .catch((err) => {
        setErrorMessage(err);
      })
      .finally(() => {
        setLoading(false);
      });
    store.dispatch(fetchSiteGraph({ projectId, siteId }));
  }, [projectId, siteId]);

  if (loading) {
    return (
      <Dimmer active inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
    );
  }
  if (errorMessage != null) {
    return (
      <Message negative>
        <p>
          Error occured while fetching the{' '}
          {graph.aps?.length !== 0
            ? formatMessage(additionalMessages.network_site)
            : formatMessage(additionalMessages.subscriber_site)}{' '}
          details
        </p>
      </Message>
    );
  }

  const deleteSite = () => {
    store.dispatch(
      uiConfirmAction({
        header: formatMessage(additionalMessages.deleteSites),
        message: formatMessage(additionalMessages.confirm),
        size: 'mini',
        onConfirm: () => {
          setLoading(true);
          postWithAuth(
            `project/${projectId}/sites`,
            {
              kind: panelKind,
              ids: [siteId],
            },
            'DELETE'
          )
            .then(() => {
              store.dispatch(fetchSites(projectId));
              navigate(path);
              setLoading(false);
            })
            .catch(console.error);
        },
      })
    );
  };

  const toolbar = (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {permissionWrite && (
        <>
          <Button
            icon="trash alternative"
            basic
            color="red"
            title={'Delete'}
            onClick={deleteSite}
            style={{ margin: '0' }}
          />
          {currentSite?.is_network_site && <ToolbarSeparator />}
        </>
      )}
      {currentSite?.is_network_site ? (
        <NetworkSiteToolbar
          formatMessage={formatMessage}
          hideButton={hideButton}
          hasTowerSwitch={hasTowerSwitch}
          setHasTowerSwitch={setHasTowerSwitch}
          graph={graph}
          siteId={siteId}
          currentSite={currentSite}
          projectId={projectId}
        />
      ) : null}
    </div>
  );

  return (
    <div
      style={{
        width: '39vw',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <PanelHeading title={currentSite?.name} toolbar={toolbar} />
      {currentSite?.name ? (
        <HideButtonContext.Provider value={setHideButton}>
          <SiteDetailsPanel
            siteId={siteId}
            isNetworkSite={currentSite?.is_network_site}
            graph={graph}
            name={currentSite?.name}
            currentSite={currentSite}
            {...props}
          />
        </HideButtonContext.Provider>
      ) : (
        <Loader active inline />
      )}
    </div>
  );
};

export default injectIntl(SitePanel);
