import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import { getWithAuth, postWithAuth } from 'src/api';
import SMForm from './SMForm';
import { getSMCacheKeys } from './utils';

function SMPanel() {
  const { id } = useParams();
  const projectId = useSelector((state) => state.mainFrame.projectId);
  const { key, choicesKey, profileKey } = getSMCacheKeys(projectId, id);
  const { data: sm } = useQuery(key, async () => {
    const sm = await getWithAuth(`project/${projectId}/subscriber/${id}`);
    return sm;
  });

  const { data: ap } = useQuery(
    [projectId, 'access_point', id],
    async () => {
      const ap = await getWithAuth(
        `project/${projectId}/access_point/${sm.access_point.id}`
      );
      return ap;
    },
    {
      enabled: sm != null,
    }
  );

  const { data: profile } = useQuery(
    profileKey,
    async () => {
      const params = new URLSearchParams({
        project_id: projectId,
        loc_lat: sm.local.latitude,
        loc_lng: sm.local.longitude,
        rem_lat: sm.remote.latitude,
        rem_lng: sm.remote.longitude,
      });
      return await getWithAuth(`profile?${params}`);
    },
    {
      enabled: sm != null,
    }
  );

  const { data: choices } = useQuery(
    choicesKey,
    async () => {
      const choices = await postWithAuth(`equipment/subscriber/choices`, {
        project_id: projectId,
        sm: { ...sm.subscriber, results: sm.results },
      });
      const equipmentChoiceMap = {};
      choices['equipment'].forEach((attr) => {
        equipmentChoiceMap[attr.attr_name] = attr;
      });
      choices['equipment'] = equipmentChoiceMap;
      return choices;
    },
    { enabled: sm != null }
  );

  if (sm != null && choices != null && profile != null && ap != null) {
    return (
      <SMForm
        id={id}
        projectId={projectId}
        choices={choices}
        profile={profile}
        sm={sm}
        ap={ap}
      ></SMForm>
    );
  }
  return <Loader active />;
}

export default SMPanel;
