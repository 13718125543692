import React from 'react';
import {
  Table,
  TableBodyProps,
  TableHeaderProps,
  TableRowProps,
} from 'semantic-ui-react';

export type TableProps = {
  header: string[];
  rows: string[][];
  headerProps?: TableHeaderProps;
  rowProps?: TableRowProps;
  bodyProps?: TableBodyProps;
} & typeof Table;

function TableComponent({
  header,
  rows,
  headerProps = {},
  rowProps = {},
  bodyProps = {},
  ...rest
}) {
  return (
    <Table {...rest} textAlign="center">
      <Table.Header {...headerProps}>
        <Table.Row>
          {header.map((item, index) => {
            const width = item.width ? item.width : 1;
            const value = item.value ? item.value : item;
            return (
              <Table.HeaderCell key={index} width={width}>
                {value}
              </Table.HeaderCell>
            );
          })}
        </Table.Row>
      </Table.Header>
      <Table.Body singleLine={true} {...bodyProps}>
        {rows.map((data, index) => {
          return (
            <Table.Row key={index} {...rowProps}>
              {data.map((item, colIndex) => (
                <Table.Cell
                  style={{ whiteSpace: 'nowrap' }}
                  key={`${index}-${colIndex}`}
                >
                  {item}
                </Table.Cell>
              ))}
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
}

export default TableComponent;
