import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Grid, Loader, Message } from 'semantic-ui-react';
import { getWithAuth } from 'src/api';

const SitePerformanceSummary = (props) => {
  const { projectId, siteId } = props;
  const [perSummaryOfSite, setPerSummaryOfSite] = useState([]);
  const [performanceSummaryCallStatus, setPerformanceSummaryCallStatus] =
    useState('');
  useEffect(() => {
    setPerSummaryOfSite([]);
    setPerformanceSummaryCallStatus('');
    getWithAuth(`project/${projectId}/site/${siteId}/performancesummary`)
      .then((res) => {
        if (res) setPerSummaryOfSite(Object.entries(res));
        else setPerSummaryOfSite(Object.entries({ 'No Network Devices': '' }));
      })
      .catch((err) => {
        setPerformanceSummaryCallStatus('Error Loading Data.');
      });
  }, [siteId, projectId]);
  if (performanceSummaryCallStatus) {
    return (
      <Message size="small" color="red">
        {performanceSummaryCallStatus}
      </Message>
    );
  }
  return perSummaryOfSite.length ? (
    <Grid className="performance-summary-grid">
      {perSummaryOfSite.map((SitePerSumRow, i) => {
        return (
          <Grid.Row
            columns={2}
            className="performance-summary-grid-row"
            key={i}
          >
            {SitePerSumRow.map((SitePerSumCol, i) => {
              return <Grid.Column key={i}>{SitePerSumCol}</Grid.Column>;
            })}
          </Grid.Row>
        );
      })}
    </Grid>
  ) : (
    <Loader active inline="centered" />
  );
};

export default injectIntl(SitePerformanceSummary);
