import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { batch, useSelector } from 'react-redux';
import {
  Button,
  Divider,
  Form,
  Grid,
  Header,
  Modal,
  Segment,
  Label,
  Icon,
  Input,
  Message,
} from 'semantic-ui-react';
import { Formik, Form as FormFormik, useFormikContext } from 'formik';
import SiteSelection from 'src/components/SiteSelection';
import { store } from 'src/store';
import {
  HEIGHT_ABOVE_CLUTTER,
  HEIGHT_ABOVE_GROUND,
  MIN_HEIGHT_ABOVE_CLUTTER,
  setCreateModalVisibility,
  SITE_MAX_HEIGHT,
  startLocationSelection,
  VS_BAND_CHOICES,
  resetSelectedLocations,
  setPendingViewsheds,
} from './viewshed.reducer';
import additionalMessages from '../../messages';
import SemanticField from 'src/components/controls/SemanticField';
import { postWithAuth } from 'src/api';
import { toast } from 'react-toastify';
import {
  MILES_TO_KILOMETERS,
  METER_TO_FEET,
  KILOMETERS_TO_MILES,
  FEET_TO_METER,
} from 'src/app.constants';
import { getCanvas } from 'src/utils/mapUtils';
import { states } from '../map/leafletCanvas';
import { get } from 'lodash';

const DEFAULT_INITIAL_VALUES = {
  siteIds: [],
  locations: [],
  // tower settings
  highResViewshed: false,
  towerHeight: 10,
  towerHeightType: SITE_MAX_HEIGHT,
  radius: 5, // km
  band: '5.8 GHz',
  color: '#FF0000',
  radioLos: true,
  useClutter: true,
  // subscriber settings
  remoteHeightType: HEIGHT_ABOVE_GROUND,
  heightAboveGround: 10,
  heightAboveClutter: 10,
};

const MAX_VIEWSHED_RADIUS = {
  highRes: {
    km: 5.0,
    mi: 3.1,
  },
  normalRes: {
    km: 30,
    mi: 18,
  },
};

const MIN_VIEWSHED_RADIUS = {
  highRes: {
    km: 0.02,
    mi: 0.01,
  },
  normalRes: {
    km: 0.1,
    mi: 0.06,
  },
};

const MIN_HEIGHT = {
  m: 0,
  ft: 0,
};

const MAX_HEIGHT = {
  m: 3000,
  ft: 9842.5,
};

function closeModal() {
  batch(() => {
    const canvas = getCanvas();
    store.dispatch(setCreateModalVisibility(false));
    store.dispatch(resetSelectedLocations());
    canvas.setState(states.MAP_VIEWSHED_MODE);
    canvas.resetViewshedSelection();
  });
}

function selectLocationsInMap(values) {
  const data = {
    radius: values.radius * 1000,
    color: values.color,
  };
  store.dispatch(startLocationSelection({ data, values }));
}

function processSites(networkSites) {
  if (!networkSites?.features) {
    return [];
  }

  return networkSites.features.map((site) => site.properties);
}

function LocationsPanel(props) {
  const { locations, initialValues, setInitialValues } = props;
  const { setFieldValue, values } = useFormikContext();
  const [editingId, setEditingId] = useState(null);
  const [editLat, setEditLat] = useState(null);
  const [editLng, setEditLng] = useState(null);

  let result = locations.map((loc, i) => {
    return (
      <Grid.Row
        style={{ alignItems: 'center', borderBottom: '1px #eaeaea solid' }}
      >
        <Grid.Column>
          {editingId === i ? (
            <>
              <Input
                label="Latitude"
                value={editLat}
                size="small"
                style={{ marginBottom: '0.5rem' }}
                onChange={(e) => setEditLat(parseFloat(e.target.value))}
              />
              <Input
                label="Longitude"
                value={editLng}
                size="small"
                onChange={(e) => setEditLng(parseFloat(e.target.value))}
              />
            </>
          ) : (
            <>
              {loc.lat.toFixed(5)}, {loc.lng.toFixed(5)}
            </>
          )}
        </Grid.Column>
        <Grid.Column floated="right" style={{ textAlign: 'right' }}>
          <Button.Group>
            {editingId === i ? (
              <Button
                icon
                onClick={() => {
                  const copy = [...values.locations];
                  copy[i] = {
                    lat: parseFloat(editLat),
                    lng: parseFloat(editLng),
                  };
                  setFieldValue('locations', copy);
                  setInitialValues({ ...initialValues, locations: copy });
                  setEditingId(null);
                  setEditLat(null);
                  setEditLng(null);
                }}
              >
                <Icon name="save" />
              </Button>
            ) : (
              <Button
                icon
                onClick={() => {
                  setEditingId(i);
                  setEditLat(loc.lat.toFixed(5));
                  setEditLng(loc.lng.toFixed(5));
                }}
              >
                <Icon name="edit" />
              </Button>
            )}
            {editingId === i && (
              <Button
                icon
                onClick={() => {
                  setEditingId(null);
                  setEditLat(null);
                  setEditLng(null);
                }}
              >
                <Icon name="delete" />
              </Button>
            )}
            {editingId !== i && (
              <Button
                icon
                onClick={() => {
                  getCanvas().removeViewshedMarker(i, loc.lat, loc.lng);
                  const copy = [...values.locations];
                  copy.splice(i, 1);
                  setFieldValue('locations', copy);
                  setInitialValues({ ...initialValues, locations: copy });
                }}
              >
                <Icon name="trash alternate" />
              </Button>
            )}
          </Button.Group>
        </Grid.Column>
      </Grid.Row>
    );
  });

  return (
    <div style={{ flex: '1 1 0%', overflowY: 'auto' }}>
      <h3>New Locations</h3>
      <Segment>
        <Grid columns="equal">{result}</Grid>
      </Segment>
    </div>
  );
}

function CreateViewshedModal(props) {
  const { formatMessage } = props.intl;

  const { projectId, prefs, networkSites } = useSelector(
    (state) => state.mainFrame
  );
  const { rangeUnits, heightUnits } = prefs;
  const [rangeUnitsScaleFactor, setRangeUnitsScaleFactor] = useState(1);
  const [heightUnitsScaleFactor, setHeightUnitsScaleFactor] = useState(1);

  const getMaxHeightError = (key, errors) => {
    return {
      content: `Max height value is ${MAX_HEIGHT[heightUnits]}${heightUnits}`,
      pointing: 'below',
    };
  };

  const getMinHeightError = () => {
    return {
      content: `Min height value is ${MIN_HEIGHT[heightUnits]}${heightUnits}`,
      pointing: 'below',
    };
  };

  const setError = (key, errors) => {
    errors[
      key
    ] = `Max height value is ${MAX_HEIGHT[heightUnits]}${heightUnits}`;
  };

  useEffect(() => {
    if (heightUnits === 'ft') {
      setHeightUnitsScaleFactor(METER_TO_FEET);
    } else {
      setHeightUnitsScaleFactor(1);
    }
  }, [heightUnits]);

  useEffect(() => {
    if (rangeUnits === 'mi') {
      setRangeUnitsScaleFactor(KILOMETERS_TO_MILES);
    } else {
      setRangeUnitsScaleFactor(1);
    }
  }, [rangeUnits]);

  const { creating, selectingLocations, selectedLocations } = useSelector(
    (state) => state.viewsheds
  );

  const [initialValues, setInitialValues] = useState(DEFAULT_INITIAL_VALUES);
  const [createBtnDisableOnModalSubmit, setCreateBtnDisableOnModalSubmit] =
    useState(false);

  const onCloseModal = (resetForm) => {
    return () => {
      // function call order here appears to be important
      setInitialValues(DEFAULT_INITIAL_VALUES);
      resetForm();
      closeModal();
      setCreateBtnDisableOnModalSubmit(false);
    };
  };

  const disabledProps = (condition) => {
    // required to override the style opacity (usually 1) to indicate fields are
    // disabled
    if (condition) {
      return { disabled: true, style: { opacity: 0.5 } };
    }
    return {};
  };

  const formatValues = (unitCoverter, value) => {
    return (unitCoverter * value).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
  };

  const getMaxViewshedRadius = ({ highResViewshed }) => {
    return highResViewshed
      ? MAX_VIEWSHED_RADIUS.highRes[rangeUnits]
      : MAX_VIEWSHED_RADIUS.normalRes[rangeUnits];
  };

  const getMinViewshedRadius = ({ highResViewshed }) => {
    return highResViewshed
      ? MIN_VIEWSHED_RADIUS.highRes[rangeUnits]
      : MIN_VIEWSHED_RADIUS.normalRes[rangeUnits];
  };

  const heightsAndRadiusValid = (values) => {
    let HeightsValid = true;
    const { towerHeight, heightAboveGround, heightAboveClutter } = values;
    let arr = [];
    [HEIGHT_ABOVE_CLUTTER, HEIGHT_ABOVE_GROUND].includes(
      values.towerHeightType
    ) && arr.push(towerHeight);
    [HEIGHT_ABOVE_CLUTTER, MIN_HEIGHT_ABOVE_CLUTTER].includes(
      values.remoteHeightType
    ) && arr.push(heightAboveClutter);
    [HEIGHT_ABOVE_GROUND, MIN_HEIGHT_ABOVE_CLUTTER].includes(
      values.remoteHeightType
    ) && arr.push(heightAboveGround);
    const minError = Math.min(...arr) < MIN_HEIGHT[heightUnits];
    const maxError = Math.max(...arr) > MAX_HEIGHT[heightUnits];
    const reqError = arr.indexOf('') !== -1;
    HeightsValid = !minError && !maxError && !reqError;

    let radiusValid = true;
    if (
      values.radius > getMaxViewshedRadius(values) ||
      values.radius < getMinViewshedRadius(values)
    ) {
      radiusValid = false;
    }
    return HeightsValid && radiusValid;
  };

  const getFieldProps = (name, value) => {
    let props = {
      name: name,
      onBlur: () => {},
    };
    return value ? Object.assign(props, { value: value }) : props;
  };

  const onSubmit = (values, { resetForm, setSubmitting }) => {
    setCreateBtnDisableOnModalSubmit(true);
    const data = {
      sites: values.siteIds,
      locations: values.locations.map((l) => ({
        latitude: l.lat,
        longitude: l.lng,
      })),
      radius:
        rangeUnits === 'mi'
          ? formatValues(MILES_TO_KILOMETERS, values.radius)
          : values.radius, // converting to km if it is in miles
      tower_height_type: values.towerHeightType,
      tower_height:
        heightUnits === 'ft'
          ? formatValues(FEET_TO_METER, values.towerHeight)
          : values.towerHeight, // converting to meters if it is in feet
      remote_height_type: values.remoteHeightType,
      remote_height:
        heightUnits === 'ft'
          ? formatValues(FEET_TO_METER, values.heightAboveGround)
          : values.heightAboveGround, // converting to meters if it is in feet
      remote_height_clutter:
        heightUnits === 'ft'
          ? formatValues(FEET_TO_METER, values.heightAboveClutter)
          : values.heightAboveClutter, // converting to meters if it is in feet
      radio_los: values.radioLos,
      band: values.band,
      include_clutter: values.useClutter,
      color: values.color,
      use_lidar: values.highResViewshed,
    };

    postWithAuth(`project/${projectId}/viewsheds`, data)
      .then((response) => {
        store.dispatch(setPendingViewsheds(response));
        onCloseModal(resetForm)();
      })
      .catch((err) => {
        let msg = get(err, 'detail[0].msg', get(err, 'detail', ''));
        if (msg.includes('ensure this value')) {
          // HACK!
          msg = 'The radius must be between 0.010 and 30 km';
        }
        if (msg === 'At least one site in embargo zone') {
          toast(<Message error>{msg}</Message>);
          onCloseModal(resetForm)();
        } else if (msg) {
          toast(<Message error>{msg}</Message>, {
            autoClose: false,
          });
        }
      });

    setSubmitting(false);
  };

  useEffect(() => {
    const { sites, locations, formState } = selectedLocations;
    batch(() => {
      // copy sites and locations from the map selection state into formik
      setInitialValues({
        ...initialValues,
        ...(formState || {}),
        siteIds: sites,
        locations: locations,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocations]);

  useEffect(() => {
    if (creating) {
      // if it is create view shed with this config then will take value from formState else initialValues
      const { formState } = selectedLocations;
      if (!formState) return;

      const values = {};
      for (const property in initialValues) {
        const value =
          property in formState && formState[property] !== null
            ? formState[property]
            : initialValues[property];
        // if property has type number, multiply its value to its respective scale factor
        if (typeof value === 'number') {
          if (property === 'towerHeightType') {
            const towerHeightTypeValues = {
              0: 'above_ground',
              1: 'above_clutter',
              3: 'site_max_height',
            };
            values[property] = towerHeightTypeValues[value];
          } else {
            const isHeight = property.toLowerCase().includes('height');
            const scaleFactor = isHeight
              ? heightUnitsScaleFactor
              : rangeUnitsScaleFactor;

            values[property] = isHeight
              ? Math.round(value * scaleFactor * 10) / 10
              : Math.round(value * scaleFactor * 100) / 100;
          }
        } else {
          values[property] = value;
        }
      }
      setInitialValues({ ...values });
    }
  }, [creating]);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        handleSubmit,
        handleChange,
        resetForm,
        setFieldValue,
        isSubmitting,
      }) => {
        return (
          <FormFormik>
            <Modal
              open={creating && !selectingLocations}
              onClose={onCloseModal(resetForm)}
            >
              <Modal.Header>
                Create Viewshed
                <Button
                  circular
                  icon="close"
                  title={formatMessage(additionalMessages.close)}
                  floated="right"
                  onClick={onCloseModal(resetForm)}
                />
              </Modal.Header>
              <Modal.Content className="ui form">
                <Grid columns="equal">
                  <Grid.Row>
                    <Grid.Column
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <SiteSelection
                        sites={processSites(networkSites)}
                        flex={true}
                      />
                      {values.locations.length ? (
                        <LocationsPanel
                          locations={values.locations}
                          initialValues={initialValues}
                          setInitialValues={setInitialValues}
                        />
                      ) : null}
                      <Button
                        primary
                        fluid
                        className="flex-grow-0 mt-2"
                        onClick={() => {
                          // update the initial values for siteIds so that they will
                          // still be selected when user returns to modal
                          setInitialValues({
                            ...initialValues,
                            siteIds: values.siteIds,
                          });
                          selectLocationsInMap(values);
                        }}
                      >
                        Select locations in map
                      </Button>
                    </Grid.Column>
                    <Grid.Column stretched>
                      <Segment>
                        <Header as="h3">
                          <FormattedMessage
                            id="createViewshedModal.towerConfig"
                            defaultMessage="Tower Configuration"
                          />
                        </Header>

                        <SemanticField
                          id="highResViewshed"
                          {...getFieldProps('highResViewshed', null)}
                          component={Form.Checkbox}
                          label={formatMessage(
                            additionalMessages.highResViewshed
                          )}
                          onChange={(e, { checked }) => {
                            const band = !values.highResViewshed
                              ? '60 GHz'
                              : DEFAULT_INITIAL_VALUES.band;
                            setFieldValue('band', band);

                            // set the max viewshed radius as default radius value
                            // when high res viewshed is enabled
                            const radius = !values.highResViewshed
                              ? MAX_VIEWSHED_RADIUS.highRes[rangeUnits]
                              : values.radius;
                            setFieldValue('radius', radius);
                            // when high res is unchecked tower height
                            // should default to site max height
                            if (!checked) {
                              setFieldValue('towerHeightType', SITE_MAX_HEIGHT);
                              setFieldValue(
                                'remoteHeightType',
                                HEIGHT_ABOVE_GROUND
                              );
                            }
                          }}
                        />

                        <h4>Tower Height</h4>

                        <SemanticField
                          component={Form.Radio}
                          label={formatMessage(
                            additionalMessages.useSiteMaxHeight
                          )}
                          {...getFieldProps('towerHeightType', SITE_MAX_HEIGHT)}
                        />
                        {!values.highResViewshed ? (
                          <SemanticField
                            component={Form.Radio}
                            label={formatMessage(
                              additionalMessages.useTowerHeight
                            )}
                            {...getFieldProps(
                              'towerHeightType',
                              HEIGHT_ABOVE_GROUND
                            )}
                          />
                        ) : (
                          <>
                            <SemanticField
                              component={Form.Radio}
                              label={formatMessage(
                                additionalMessages.useTowerHeightAboveGround
                              )}
                              {...getFieldProps(
                                'towerHeightType',
                                HEIGHT_ABOVE_GROUND
                              )}
                            />
                            <SemanticField
                              component={Form.Radio}
                              label={formatMessage(
                                additionalMessages.useTowerHeightAboveClutter
                              )}
                              {...getFieldProps(
                                'towerHeightType',
                                HEIGHT_ABOVE_CLUTTER
                              )}
                            />
                          </>
                        )}

                        <Divider />

                        <SemanticField
                          {...getFieldProps('towerHeight', values.towerHeight)}
                          type="number"
                          precision={1}
                          allowFirstZero={false}
                          error={
                            [
                              HEIGHT_ABOVE_GROUND,
                              HEIGHT_ABOVE_CLUTTER,
                            ].includes(values.towerHeightType)
                              ? parseFloat(values.towerHeight) >
                                MAX_HEIGHT[heightUnits]
                                ? getMaxHeightError()
                                : parseFloat(values.towerHeight) <
                                  MIN_HEIGHT[heightUnits]
                                ? getMinHeightError()
                                : values.towerHeight === ''
                                ? { content: 'Required' }
                                : false
                              : false
                          }
                          step={0.1}
                          component={Form.Input}
                          label={formatMessage(additionalMessages.towerHeight)}
                          {...disabledProps(
                            (!values.highResViewshed &&
                              values.towerHeightType !== HEIGHT_ABOVE_GROUND) ||
                              (values.highResViewshed &&
                                values.towerHeightType === SITE_MAX_HEIGHT)
                          )}
                        >
                          <input />
                          <Label>{heightUnits}</Label>
                        </SemanticField>

                        <SemanticField
                          {...getFieldProps('radius', values.radius)}
                          component={Form.Input}
                          type="number"
                          precision={2}
                          allowFirstZero={true}
                          error={
                            values.radius > getMaxViewshedRadius(values)
                              ? {
                                  content: `Max radius value is ${getMaxViewshedRadius(
                                    values
                                  )}${rangeUnits}`,
                                  pointing: 'below',
                                }
                              : parseFloat(values.radius) <
                                getMinViewshedRadius(values)
                              ? {
                                  content: `Min radius value is ${getMinViewshedRadius(
                                    values
                                  )}${rangeUnits}`,
                                  pointing: 'below',
                                }
                              : values.radius === ''
                              ? {
                                  content: `Required`,
                                }
                              : false
                          }
                          label={formatMessage(additionalMessages.radius)}
                        >
                          <input />
                          <Label>{rangeUnits}</Label>
                        </SemanticField>

                        <SemanticField
                          {...getFieldProps('radioLos', null)}
                          component={Form.Checkbox}
                          label={formatMessage(additionalMessages.radioLos)}
                          {...disabledProps(values.highResViewshed)}
                        />

                        <div className="field">
                          <label htmlFor="viewshedBandSelect">
                            {formatMessage(additionalMessages.band)}
                          </label>
                          <select
                            id="viewshedBandSelect"
                            name="band"
                            value={values.band}
                            onChange={handleChange}
                            {...disabledProps(!values.radioLos)}
                          >
                            {VS_BAND_CHOICES.map((c) => (
                              <option value={c} key={c}>
                                {c}
                              </option>
                            ))}
                          </select>
                        </div>

                        <SemanticField
                          {...getFieldProps('color', null)}
                          component={Form.Input}
                          label={formatMessage(additionalMessages.color)}
                          className="clutter-color"
                          width={5}
                          type="color"
                        />

                        <SemanticField
                          {...getFieldProps('useClutter', null)}
                          component={Form.Checkbox}
                          label={formatMessage(additionalMessages.useClutter)}
                          onChange={(e, data) => {
                            if (data.meta.value) {
                              setFieldValue(
                                'remoteHeightType',
                                HEIGHT_ABOVE_GROUND
                              );
                            }
                          }}
                          {...disabledProps(values.highResViewshed)}
                        />
                      </Segment>
                    </Grid.Column>
                    <Grid.Column stretched>
                      <Segment>
                        <Header as="h3">
                          <FormattedMessage
                            id="createViewshedModal.subscriberConfig"
                            defaultMessage="Subscriber Configuration"
                          />
                        </Header>

                        <h4>Subscriber Height</h4>

                        <SemanticField
                          component={Form.Radio}
                          label={formatMessage(
                            additionalMessages.useHeightAboveGround
                          )}
                          {...getFieldProps(
                            'remoteHeightType',
                            HEIGHT_ABOVE_GROUND
                          )}
                        />
                        <SemanticField
                          component={Form.Radio}
                          label={formatMessage(
                            additionalMessages.useHeightAboveClutter
                          )}
                          disabled={
                            !values.useClutter && !values.highResViewshed
                          }
                          {...getFieldProps(
                            'remoteHeightType',
                            HEIGHT_ABOVE_CLUTTER
                          )}
                        />
                        <SemanticField
                          component={Form.Radio}
                          label={formatMessage(
                            additionalMessages.useMinHeightAboveClutter
                          )}
                          disabled={
                            !values.useClutter && !values.highResViewshed
                          }
                          {...getFieldProps(
                            'remoteHeightType',
                            MIN_HEIGHT_ABOVE_CLUTTER
                          )}
                        />

                        <Divider />

                        <SemanticField
                          {...getFieldProps(
                            'heightAboveGround',
                            values.heightAboveGround
                          )}
                          type="number"
                          precision={1}
                          allowFirstZero={false}
                          error={
                            [
                              HEIGHT_ABOVE_GROUND,
                              MIN_HEIGHT_ABOVE_CLUTTER,
                            ].includes(values.remoteHeightType)
                              ? parseFloat(values.heightAboveGround) >
                                MAX_HEIGHT[heightUnits]
                                ? getMaxHeightError()
                                : parseFloat(values.heightAboveGround) <
                                  MIN_HEIGHT[heightUnits]
                                ? getMinHeightError()
                                : values.heightAboveGround === ''
                                ? { content: 'Required' }
                                : false
                              : false
                          }
                          step={0.1}
                          component={Form.Input}
                          label={formatMessage(
                            additionalMessages.heightAboveGround
                          )}
                          {...disabledProps(
                            values.remoteHeightType === HEIGHT_ABOVE_CLUTTER
                          )}
                        >
                          <input />
                          <Label>{heightUnits}</Label>
                        </SemanticField>
                        <SemanticField
                          {...getFieldProps(
                            'heightAboveClutter',
                            values.heightAboveClutter
                          )}
                          type="number"
                          precision={1}
                          allowFirstZero={false}
                          error={
                            [
                              HEIGHT_ABOVE_CLUTTER,
                              MIN_HEIGHT_ABOVE_CLUTTER,
                            ].includes(values.remoteHeightType)
                              ? parseFloat(values.heightAboveClutter) >
                                MAX_HEIGHT[heightUnits]
                                ? getMaxHeightError()
                                : parseFloat(values.heightAboveClutter) <
                                  MIN_HEIGHT[heightUnits]
                                ? getMinHeightError()
                                : values.heightAboveClutter === ''
                                ? { content: 'Required' }
                                : false
                              : false
                          }
                          step={0.1}
                          component={Form.Input}
                          label={formatMessage(
                            additionalMessages.heightAboveClutter
                          )}
                          {...disabledProps(
                            values.remoteHeightType === HEIGHT_ABOVE_GROUND
                          )}
                        >
                          <input />
                          <Label>{heightUnits}</Label>
                        </SemanticField>
                      </Segment>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Modal.Content>
              <Modal.Actions>
                <Button onClick={onCloseModal(resetForm)}>Cancel</Button>
                <Button
                  color="blue"
                  onClick={handleSubmit}
                  loading={isSubmitting}
                  disabled={
                    (values.siteIds.length === 0 &&
                      values.locations.length === 0) ||
                    values.radius > getMaxViewshedRadius(values) ||
                    createBtnDisableOnModalSubmit ||
                    !heightsAndRadiusValid(values)
                  }
                >
                  Create
                </Button>
              </Modal.Actions>
            </Modal>
          </FormFormik>
        );
      }}
    </Formik>
  );
}

export default injectIntl(CreateViewshedModal);
