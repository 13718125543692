import React, { useEffect, useState, useContext } from 'react';
import { Form, Label, Segment } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import { Formik, Form as FormFormik } from 'formik';

import { store } from '../../store';
import * as Yup from 'yup';
import {
  decodeLatLong,
  encodeLatLong,
  isEmptyObject,
} from '../../utils/useful_functions';
import additionalMessages from '../../messages';
import LatLngControl from '../../components/controls/LatLngControl';
import SemanticField from '../../components/controls/SemanticField';
import { batch, useSelector } from 'react-redux';
import { postWithAuth } from '../../api';
import { fetchSites } from '../mainframe/mainframe.reducer';
import HeightControl from 'src/components/controls/HeightControl';
import { HideButtonContext } from 'src/pages/sites/SitePanel.jsx';
import RouteLeavingGuard from 'src/components/RouteLeavingGuard';

const SiteProperties = (props) => {
  const hideButton = useContext(HideButtonContext);
  const { siteId, isNetworkSite } = props;
  const { formatMessage } = props.intl;
  const {
    id,
    name,
    latitude,
    longitude,
    maximum_height,
    description,
    node_type,
  } = props.currentSite;
  const { site_owner } = props.currentSite.properties;
  const path = document.location.pathname;
  const { prefs, projectId } = useSelector((state) => state.mainFrame);
  const [error, setError] = useState(null);
  const [isMaxHeightError, setIsMaxHeightError] = useState(false); // used to control maximum height through form number control component
  const [modified, setModified] = useState(false);
  const [maxHeight, setMaxHeight] = useState(maximum_height);
  const [scaledMaxHeight, setScaledMaxHeight] = useState(maximum_height);
  useEffect(() => {
    let height = maximum_height;
    // TODO we need to why it's not rounding with precision when we switch between feet to meter
    if (prefs.heightUnits === 'm')
      height = parseFloat(maximum_height).toFixed(1);
    setMaxHeight(height);
    setScaledMaxHeight(height);
  }, [id]);

  useEffect(() => {
    setModified(false);
  }, [siteId, id]);

  useEffect(() => {
    hideButton(modified);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modified]);

  // maximum height validation is taken care from number control component, hence removed it from YUP library validation
  const validationSchema = Yup.object({
    name: Yup.string()
      .trim()
      .required(
        formatMessage(additionalMessages.requiredFieldError, {
          fieldName: 'name',
        })
      ),
    latitude: Yup.string()
      .trim()
      .required(
        formatMessage(additionalMessages.requiredFieldError, {
          fieldName: 'latitude',
        })
      ),
    longitude: Yup.string()
      .trim()
      .required(
        formatMessage(additionalMessages.requiredFieldError, {
          fieldName: 'longitude',
        })
      ),
  });

  const initialValues = {
    name,
    latitude,
    longitude,
    description,
    maximum_height,
    node_type: node_type,
  };

  return (
    <>
      <Formik
        key={`${name}_${id}`}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          values.maximum_height = maxHeight;
          values.latitude = decodeLatLong(values.latitude)[0];
          values.longitude = decodeLatLong(values.longitude, false)[0];

          postWithAuth(`project/${projectId}/site/${siteId}`, values, 'PATCH')
            .then((response) => {
              if (response.length === 0) {
                actions.resetForm({
                  values: {
                    ...initialValues,
                    latitude: encodeLatLong(
                      initialValues.latitude,
                      prefs.latLngFormat,
                      true
                    ),
                    longitude: encodeLatLong(
                      initialValues.longitude,
                      prefs.latLngFormat,
                      false
                    ),
                  },
                });
                return;
              }
              batch(() => {
                // TODO need to redraw map some how
                store.dispatch(fetchSites(projectId));
              });
              setModified(false);
              // reset to re-encode lat/lng according to user prefs
              actions.resetForm({
                values: {
                  name: values.name,
                  description: values.description,
                  maximum_height: values.maximum_height,
                  latitude: encodeLatLong(
                    values.latitude,
                    prefs.latLngFormat,
                    true
                  ),
                  longitude: encodeLatLong(
                    values.longitude,
                    prefs.latLngFormat,
                    false
                  ),
                },
              });
            })
            .catch((err) => {
              console.error(err);
              setError(err);
            });
        }}
        validateOnMount
      >
        {({ errors }) => (
          <FormFormik id="siteForm" className="ui form" widths="equal">
            {error ? (
              <Segment inverted color="red">
                An error occurred when updating this site
              </Segment>
            ) : null}
            <Form.Group widths="equal">
              <SemanticField
                component={Form.Input}
                label={formatMessage(additionalMessages.name)}
                name="name"
                minLength={1}
                maxLength={60}
                required
                autoFocus
                onChange={() => setModified(true)}
              />
              <HeightControl
                label={formatMessage(additionalMessages.maxHeight)}
                value={
                  prefs.heightUnits === 'm' && modified
                    ? maxHeight
                    : scaledMaxHeight
                }
                min={0}
                max={3000}
                precision={1}
                valueType="decimal"
                step={0.1}
                width={16}
                name="maximumHeight"
                onChange={(e, { value, unScaledVal, error }) => {
                  if (!isNaN(unScaledVal)) setMaxHeight(unScaledVal);
                  setScaledMaxHeight(value);
                  setModified(true);
                  setIsMaxHeightError(error);
                }}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <LatLngControl
                label={formatMessage(additionalMessages.latitude)}
                name="latitude"
                controlValue={latitude}
                toFormat={prefs.latLngFormat}
                required
                onChange={() => setModified(true)}
              />
              <LatLngControl
                isLat={false}
                label={formatMessage(additionalMessages.longitude)}
                name="longitude"
                controlValue={longitude}
                toFormat={prefs.latLngFormat}
                required
                onChange={() => setModified(true)}
              />
            </Form.Group>
            {isNetworkSite && (
              <SemanticField
                component={Form.Select}
                label={formatMessage(additionalMessages.nodeType)}
                options={[
                  { key: '', value: '', text: '' },
                  { key: 'DN', value: 'DN', text: 'DN' },
                  { key: 'POP', value: 'POP', text: 'POP' },
                ]}
                name="node_type"
                onChange={() => setModified(true)}
              />
            )}
            {site_owner && (
              <SemanticField
                component={Form.Input}
                label={formatMessage(additionalMessages.siteOwner)}
                name="site_owner"
                disabled={true}
              >
                {site_owner}
              </SemanticField>
            )}
            <SemanticField
              component={Form.TextArea}
              label={formatMessage(additionalMessages.description)}
              name="description"
              maxLength={500}
              onChange={() => setModified(true)}
            />
            <Form.Button
              compact
              primary
              type="submit"
              disabled={
                !modified ||
                (modified && (!isEmptyObject(errors) || isMaxHeightError))
              }
              data-testid="detailsApplyBtn"
              accesskey="a"
            >
              Apply
            </Form.Button>
          </FormFormik>
        )}
      </Formik>
      <RouteLeavingGuard
        when={modified}
        shouldBlockNavigation={() => {
          if (modified) {
            return true;
          }
          return false;
        }}
        yes="Yes"
        no="No"
        title={`${
          (path.includes('/network_sites/') &&
            formatMessage(additionalMessages.networkSites)) ||
          (path.includes('/subscriber_sites/') &&
            formatMessage(additionalMessages.subscriberSites))
        }: ${name}`}
        content={formatMessage(additionalMessages.warning)}
      />
    </>
  );
};

export default injectIntl(SiteProperties);
